import request from '@/utils/request'

export function generateOtp(data) {
    return request({
        url: '/api/otp/generate',
        method: 'get',
        params: data
    })
}

export function VerifyOtp(data) {
    return request({
        url: '/api/otp/vertify',
        method: 'get',
        params: data
    })
}

export function relateSearch(data){
    return request({
        url: '/api/relate/searchCustomer',
        method: 'post',
        params: data
    })
}

export function relateSearchByMobileAndLastName(data){
    return request({
        url: '/api/relate/searchCustomerByMobileAndLastName',
        method: 'post',
        params: data
    })
}

export function relateSearchByE(data){
    return request({
        url: '/api/relate/searchCustomerByE',
        method: 'post',
        params: data
    })
}

export function relateAddOrUpdate(data){
    return request({
        url: '/api/relate/addOrUpdate',
        method: 'post',
        data
    })
}

export function searchStoreName(data){
    return request({
        url: '/api/relate/searchStore',
        method: 'get',
        params: data
    })
}